import React from "react"
import PropTypes from "prop-types"

import Column from "./column"
import Columns from "./columns"
import Poem from "./poem"
import CounterInput from "./counter-input"

const SyllablesTask = ({ children, size, name }) => {
  return (
    <Columns alignY="center">
      <Column>
        <Poem size={size}>{children}</Poem>
      </Column>
      <Column width="content">
        <CounterInput min={0} max={30} id={name} name={name} initialValue={0} />
      </Column>
    </Columns>
  )
}

SyllablesTask.propTypes = {
  children: PropTypes.node,
  /** size of the poem */
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  /** used for validating the question */
  name: PropTypes.string,
}

SyllablesTask.defaultProps = {
  size: [2, 2, 3, 4],
}

export default SyllablesTask
